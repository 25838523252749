import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { TextField, Button, IconButton } from "@material-ui/core";
import {
  FirstPage,
  LastPage,
  VerticalAlignTop,
  VerticalAlignBottom,
} from "@material-ui/icons";
import io from "socket.io-client";

import { useMountEffect } from "../../utils/useMountEffect";

import "./Chat.scss";

const socket = io.connect(process.env.REACT_APP_CHAT_URL, {
  transports: ["websocket", "polling"],
});

const Chat = ({ event = 0, toggleHideCallback = () => {}, ...props }) => {
  const user = useSelector((state) => state.user);
  const [chatHidden, setChatHidden] = useState(false);
  const [inputBlockEnabled, setInputBlockEnabled] = useState(true);
  const [message, setMessage] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const chatWrapper = useRef();
  const messageBox = useRef();
  const chatFooter = useRef();
  const chatroom = event.toString();

  let chatHistory = useRef([]);

  const parseRank = (rankList) => {
    if (!rankList || !rankList.length || !rankList[0]) {
      return "";
    }

    if (rankList[0].icon && rankList[0].level) {
      return (
        <img
          className="chat-message-rank-icon"
          src={process.env.REACT_APP_API_URL + rankList[0].icon}
          alt={rankList[0].level}
        />
      );
    } else if (!rankList[0].url && rankList[0].level) {
      return <span className="chat-message-rank">({rankList[0].level})</span>;
    }
  };

  const sendMessage = () => {
    socket.emit(
      "message",
      {
        chatroomName: chatroom,
        message,
      },
      (data) => {}
    );
    setMessage("");
  };

  const handleMessages = (data) => {
    chatHistory.current = chatHistory.current.concat(data);

    // handle blocking of users
    if (data.type && data.type === "blocked" && data.userName) {
      chatHistory.current = deleteMessagesFromHistoryByUsername(data.userName);

      if (data.userName === user.profile.name) {
        setInputBlockEnabled(false);
      }
    }

    // handle unblocking of user
    if (
      data.type &&
      data.type === "unblocked" &&
      data.userName &&
      data.userName === user.profile.name
    ) {
      setInputBlockEnabled(true);
    }
    setChatMessages(chatHistory.current);

    if (messageBox.current) {
      messageBox.current.scrollTop = messageBox.current.scrollHeight;
    }
  };

  // removes all messages of blocked users from local history
  const deleteMessagesFromHistoryByUsername = (userName) => {
    return chatHistory.current.filter((chatMessage) => {
      if (
        !chatMessage.event &&
        chatMessage.user &&
        chatMessage.user.username === userName
      ) {
        return false;
      }
      return true;
    });
  };

  const toggleHide = () => {
    if (chatWrapper) {
      chatWrapper.current.classList.toggle("chat--hidden");
    }
    setChatHidden(!chatHidden);
    toggleHideCallback && toggleHideCallback();
  };

  const handleJoin = () => {
    socket.emit("join", user.profile.name, user.jwt, chatroom, (test, data) => {
      if (data && data.history) {
        chatHistory.current = data.history;
        // add MOTD
        chatHistory.current = chatHistory.current.concat({
          announcement: true,
          message: data.motd,
        });

        setChatMessages(chatHistory.current);
      }
      messageBox.current.scrollTop = messageBox.current.scrollHeight;
    });
  };

  useMountEffect(() => {
    if (!!~user.profile.bannedInChats.indexOf(event)) {
      setInputBlockEnabled(false);
    }

    handleJoin();

    socket.on("message", handleMessages);

    socket.on("disconnect", () => {
      setChatMessages([
        {
          message:
            "Lost connection to chat, trying to reconnect automatically...",
          announcement: true,
        },
      ]);
      console.log("Disconnected from chat...");
    });

    socket.on("connect", () => {
      handleJoin();
      console.log("Reconnected to chat...");
    });
  });

  return (
    <section
      ref={chatWrapper}
      className={`chat${!inputBlockEnabled ? " readonly" : ""}`}
    >
      <header className="chat-menu">
        <IconButton
          className="hide-small"
          size="small"
          color="secondary"
          aria-label="toggle chat"
          onClick={toggleHide}
        >
          {chatHidden ? <FirstPage /> : <LastPage />}
        </IconButton>
        <IconButton
          className="show-small"
          size="small"
          color="secondary"
          aria-label="toggle chat"
          onClick={toggleHide}
        >
          {chatHidden ? <VerticalAlignBottom /> : <VerticalAlignTop />}
        </IconButton>
      </header>
      <div ref={messageBox} id="chat-messages" className="chat-messages">
        {chatMessages.map((message) => {
          // let datetime = new Date(message.datetime).toLocaleTimeString();
          if (message.announcement && message.message) {
            return (
              <section className="chat-message" key={Math.random() * 100}>
                <i>{message.message}</i>
              </section>
            );
          } else if (message.message) {
            return (
              <section className="chat-message" key={Math.random() * 100}>
                <div className="chat-message-title">
                  <span className="chat-message-name">
                    {parseRank(message.user.ranks)} {message.user.username}
                  </span>
                </div>
                {message.message}
              </section>
            );
          }

          /* if (message.event) {
            return (
              <div>
                <i> <i>{datetime}</i> {message.user.name} {message.event}</i>
              </div>
            ) 
          } */

          return "";
        })}
      </div>
      {inputBlockEnabled && (
        <footer ref={chatFooter} className="chat-footer">
          <TextField
            color="secondary"
            value={message}
            className="chat-input"
            onChange={(event) => {
              setMessage(event.target.value);
            }}
            onKeyDown={(event) => {
              if (event.keyCode === 13) {
                sendMessage();
              }
            }}
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={sendMessage}
            className="chat-button"
          >
            Send!
          </Button>
        </footer>
      )}
    </section>
  );
};

export default Chat;
